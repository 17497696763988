import { useEffect, useState } from "react";
import styled from "styled-components";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { buildSocket, getSocket } from "./socket";
import { useSnackbar } from "notistack";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import DevicesIcon from "@mui/icons-material/Devices";

import emitAsync from "components/helper/emitAsync";
import { useUser } from "components/helper/userContext";
import { useSession } from "components/helper/sessionContext";
import { DataVoteType } from "types/dataVoteType";
import { QuestionType } from "types/questionTypes";
import { EVENT } from "types/socketEventsType";
import {
    ADDITIONAL_FIELDS_API,
    getCurrentUser,
} from "components/helper/callApi";
import { UnknownError, NetworkError, DisplayedError } from "services/errors";

import Layout from "./common/Layout";
import { BasicButton } from "./common/BasicButton";
import ChoiceQuestion from "./vote/Quizzbox/Question/ChoiceQuestion/ChoiceQuestion";
import GroupedQuestion from "./vote/Quizzbox/Question/GroupedQuestion/GroupedQuestion";
import OrderedQuestion from "./vote/Quizzbox/Question/OrderedQuestion/OrderedQuestion";
import Deliberation from "./vote/Votebox/Deliberation/Deliberation";
import GroupedDeliberation from "./vote/Votebox/Deliberation/GroupedDeliberation/GroupedDeliberation";
import Election from "./vote/Votebox/Election/Election";
import GroupedEvaluation from "./vote/Quizzbox/DisplayMode/Question/GroupedEvaluation/GroupedEvaluation";
import NumericQuestion from "./vote/Quizzbox/common/NumericQuestion";
import VoteHeader from "./vote/Votebox/common/Header/VoteHeader";
import TagCloudQuestion from "./vote/Quizzbox/Question/TagCloudQuestion";
import ErrorPage from "./common/ErrorPage";
import { Socket } from "socket.io-client";
import {
    loadZoomMeetingClient,
    toggleZoomDisplay,
} from "services/zoomMeetingService";

import DisableStreamingLogo from "./zoomIcons/deny_visio.svg";
import EnableStreamingLogo from "./zoomIcons/allow_visio.svg";
import { LS_AUTH_TOKEN_KEY } from "constants/constants";
import NoVoteAllowedMessage from "./vote/Votebox/common/NoVoteAllowedMessage";
import { LanguageMap } from "../Language";
import { Scenario } from "enums/scenario";
import {
    getAdditionalFields,
    shouldCountOnlineDevice,
} from "services/additionalFields";
import { SessionType } from "types/sessionType";
import { User } from "types/User";

type DragInfo = {
    x: number;
    y: number;
    time: number;
};

const scenarioFromSession = (scenario: string) =>
    Object.values(Scenario).find((value) => scenario === value) as Scenario;

const SessionPage = () => {
    const { i18n } = useTranslation();

    const { user, setUser } = useUser();
    const { session, setSession, setSocket, setSessionIsActive } = useSession();
    const { onlineCode } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const ALLOWED_ZOOM_VIDEO_LS_KEY = "allowedZoomVideo";

    const [isVideoAllowed, setIsAllowedVideoAllowed] = useState<boolean>(() => {
        const allowedZoomVideoCache = localStorage.getItem(
            ALLOWED_ZOOM_VIDEO_LS_KEY
        );
        return allowedZoomVideoCache
            ? (JSON.parse(allowedZoomVideoCache) as boolean)
            : true;
    });

    const [vote, setVote] = useState<DataVoteType | null>(null);
    const [error, setError] = useState<DisplayedError | null>(null);
    const [isSocketError, setIsSocketError] = useState(false);
    let [dragInfo, setDragInfo] = useState<DragInfo | null>(null);

    const [liveDeviceCount, setDeviceCountChanged] = useState<number>(0);

    useEffect(() => {
        localStorage.setItem(
            ALLOWED_ZOOM_VIDEO_LS_KEY,
            isVideoAllowed.toString()
        );
        const socketCleanUpHandler = connectWebsocketToServer(
            onlineCode as string
        );

        return () => {
            socketCleanUpHandler();
            // Since the Zoom component is injected directly into the DOM outside of React's managed tree,
            // we need to manually control its visibility. React cannot handle the display state of this
            // component because it exists independently at the end of the <body> tag. Here, we ensure
            // that the Zoom component is hidden when cleaning up the effect.
            toggleZoomDisplay("none");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onlineCode, isVideoAllowed]);

    const connectWebsocketToServer = (onlineCode: string): (() => void) => {
        buildSocket(onlineCode as string);
        const socket = getSocket();
        socket.connect();

        socket.on(EVENT.CONNECT, () => {
            setIsSocketError(false);
            setSocket(socket);
            console.log("Socket connected");
        });

        socket.on(
            EVENT.DEVICE_COUNT_CHANGED,
            ({ liveDeviceCount }: { liveDeviceCount: number }) => {
                setDeviceCountChanged(liveDeviceCount);
            }
        );

        socket.on(EVENT.VOTE_REQUEST, voteRequestHandler);

        socket.on(EVENT.CLOSE_VOTE, () => {
            setVote(null);
        });

        socket.on(EVENT.RELOAD_REQUEST, reloadRequest);

        socket.on(EVENT.KILL_PARTICIPANTS, () => {
            localStorage.removeItem(LS_AUTH_TOKEN_KEY);
            redirectToHome();
        });

        socket.on(EVENT.SESSION_CHANGE_STATUS, (isActive: boolean) => {
            setSessionIsActive(isActive);

            if (!isActive) {
                setVote(null);
            }
        });

        socket.on(EVENT.DISCONNECT, () => {
            setSessionIsActive(false);
        });

        socket.on(EVENT.CONNECT_ERROR, () => {
            setIsSocketError(true);
            setTimeout(() => {
                socket.connect();
            }, 5000);
        });

        return () => cleanup(socket);
    };

    const voteRequestHandler = async (vote: any) => {
        try {
            const currentUserRes = await getCurrentUser(
                getAdditionalFields({
                    shouldCountOnlineDevice: shouldCountOnlineDevice(
                        scenarioFromSession((session as SessionType).scenario)
                    ),
                    fields: [ADDITIONAL_FIELDS_API.PROXIES],
                })
            );

            if (currentUserRes.ok) {
                const userData = await currentUserRes.json();
                setUser(userData);
                setVote(vote.vote);
                setDeviceCountChanged((userData as User).liveDeviceCount || 0);
            } else {
                if (currentUserRes.status === 401) {
                    enqueueSnackbar(`${i18n.t("errorYouMustBeLog")}`, {
                        variant: "error",
                        persist: true,
                    });
                }
                if (currentUserRes.status === 500) {
                    setError(new UnknownError());
                }
            }
        } catch (e) {
            setError(new NetworkError());
        }
    };

    const cleanup = (socketParam: Socket<any, any>) => {
        console.log("disconnect socket Session page...");
        socketParam.disconnect();
        setSocket(null);
    };

    const reloadRequest = () => {
        navigate(`/session/${onlineCode}`);
    };

    const redirectToHome = () => {
        setUser(null);
        localStorage.removeItem(LS_AUTH_TOKEN_KEY);
        navigate(`/login/${onlineCode}`, { replace: true });
    };

    const sendQuizAnswer = (answer: string | Array<string>) => {
        const question = vote?.questions[0];
        const responseFormatVersion = vote?.responseFormatVersion;

        return emitAsync({
            votingDeviceId: user?.numTelecoEncrypted as string,
            responseFormatVersion: responseFormatVersion as number,
            answers: Array.isArray(answer)
                ? answer.map((ans) => ({
                      questionId: question?.id as number,
                      answer: ans,
                      weight: user?.weight as number,
                  }))
                : [
                      {
                          questionId: question?.id as number,
                          answer: answer,
                          weight: user?.weight as number,
                      },
                  ],
        });
    };

    const renderNoVotePendingTemplate = () => {
        return (
            <NoVoteStyled>
                <ConnectedMessage dir={i18n.dir(i18n.language)}>
                    {i18n.t("youAreConnected")}
                </ConnectedMessage>
                <p dir={i18n.dir(i18n.language)}>
                    {i18n.t(`noQuestionPending.${process.env.REACT_APP_THEME}`)}
                </p>
                <p dir={i18n.dir(i18n.language)}>
                    {i18n.t("pageRefreshAutomatically")}
                </p>
                <RefreshBtn
                    onClick={() => window.location.reload()}
                    dir={i18n.dir(i18n.language)}
                >{`${i18n.t("refresh")}`}</RefreshBtn>
            </NoVoteStyled>
        );
    };

    const renderComponentVoteTemplate = () => {
        //This check at this point does not make sens
        //It is only used as non-null typing
        //Makes User not null for linter
        if (!user) {
            return;
        }

        if (!vote) {
            return renderNoVotePendingTemplate();
        }

        const responseFormatVersion = vote.responseFormatVersion;

        const question = vote.questions[0];
        if (user.hasGivenProxy) {
            return (
                <>
                    <VoteHeader question={question} />
                    <StyledNoVoteParagraph
                        dir={i18n.dir(i18n.language)}
                    >{`${i18n.t("noVoteCauseProxy")}`}</StyledNoVoteParagraph>
                </>
            );
        }

        const noActiveProxies = user.proxies.every(
            (proxy) => proxy.weight <= 0
        );
        if (0 === user.weight && noActiveProxies) {
            return <NoVoteAllowedMessage displayName={user.displayName} />;
        }

        if (vote.questions.length > 1) {
            return (
                <GroupedDeliberation
                    title={vote.voteTitle}
                    questions={vote.questions}
                    user={user}
                    proxies={user.proxies}
                    responseFormatVersion={responseFormatVersion}
                />
            );
        }

        //Always return InputComponent for tag cloud
        if (question?.type === QuestionType.TAG_CLOUD) {
            return (
                <TagCloudQuestion
                    title={question.title}
                    onValidate={sendQuizAnswer}
                    numberOfInputs={question.maxNumberOfAnswers}
                    userCanSubmitMultipleTimes={question.multiSubmit}
                />
            );
        }

        switch (question?.type) {
            case QuestionType.NUMERICAL_CORRECT_ANSWER:
            case QuestionType.NOTE:
            case QuestionType.NUMERICAL:
            case QuestionType.ROLL_CALL:
            case QuestionType.TEST:
            case QuestionType.ID_ROLL_CALL:
            case QuestionType.ANONYMOUS_ROLL_CALL:
                return (
                    <NumericQuestion
                        question={question}
                        numTelecoEncrypted={user.numTelecoEncrypted}
                        responseFormatVersion={responseFormatVersion}
                        useDecimal={question.useDecimal}
                        minValue={question.minValue}
                        maxValue={question.maxValue}
                    />
                );
            case QuestionType.MULTIPLE_CHOICE_CORRECT_ANSWER:
            case QuestionType.MULTIPLE_CHOICE:
            case QuestionType.UNIQUE_CHOICE:
            case QuestionType.UNIQUE_CHOICE_CORRECT_ANSWER:
            case QuestionType.LIST:
            case QuestionType.TEAMS:
            case QuestionType.CLASSIFICATION:
                return (
                    <ChoiceQuestion
                        onValidate={sendQuizAnswer}
                        title={question.title}
                        minNumberOfAnswers={question.minNumberOfAnswers}
                        maxNumberOfAnswers={question.maxNumberOfAnswers}
                        options={question.propositions}
                    />
                );
            case QuestionType.GROUPED:
                return (
                    <GroupedQuestion
                        onValidate={sendQuizAnswer}
                        title={question.title}
                        subTitles={question.subTitles}
                        options={question.propositions}
                    />
                );
            case QuestionType.ORDERED_ANSWERS:
            case QuestionType.CORRECT_ORDERED_ANSWERS:
                return (
                    <OrderedQuestion
                        onValidate={sendQuizAnswer}
                        options={question.propositions}
                        title={question.title}
                    />
                );
            case QuestionType.GROUPED_EVALUATION:
                return (
                    <GroupedEvaluation
                        title={question.title}
                        evaluationQuestions={question.evaluationQuestions}
                        minValue={question.minValue}
                        maxValue={question.maxValue}
                        useDecimal={question.useDecimal}
                        evaluationDisplayType={question.evaluationDisplayType}
                        responseFormatVersion={responseFormatVersion}
                    />
                );
            case QuestionType.DELIBERATION:
                return (
                    <Deliberation
                        question={question}
                        user={user}
                        proxies={user.proxies}
                        responseFormatVersion={responseFormatVersion}
                    />
                );
            case QuestionType.ELECTION:
                if (vote.responseFormatVersion !== 1) {
                    return (
                        <Election
                            question={question}
                            user={user}
                            proxies={user.proxies}
                            responseFormatVersion={responseFormatVersion}
                        />
                    );
                }
                break;
        }

        return renderNoVotePendingTemplate();
    };

    /**
     * Useful for zoom
     * OnClick inside Draggable doesn't work on mobile mode
     * https://github.com/react-grid-layout/react-draggable/issues/550#issuecomment-1338009022
     */
    const handleDragStart = (e: DraggableEvent, data: DraggableData) => {
        setDragInfo({
            x: data.x,
            y: data.y,
            time: Date.now(),
        });
    };

    /**
     * Useful for zoom
     * OnClick inside Draggable doesn't work on mobile mode
     * https://github.com/react-grid-layout/react-draggable/issues/550#issuecomment-1338009022
     *
     * The comparison of `e.type` to "touchend" is used to determine if the event
     * was triggered on a mobile device. On mobile, touch events like "touchend" are
     * used instead of mouse events like "mouseup".
     *
     * Notice that triggering a click event unconditionally (without any checks) does not work
     * reliably in non-mobile environments. This could be due to differences in how
     * browsers handle event propagation and timing between touch and mouse events.
     */
    const handleDragStop = (e: DraggableEvent, data: DraggableData) => {
        if (!dragInfo || "touchend" !== e.type) return;

        let change = {
            x: Math.abs(data.x - dragInfo.x),
            y: Math.abs(data.y - dragInfo.y),
            time: Date.now() - dragInfo.time,
        };

        const isNotMove = change.x + change.y <= 10;
        const isClickTiming = change.time < 300;
        const shouldApplyClick = isNotMove && isClickTiming;

        if (!shouldApplyClick) {
            return;
        }

        const clickEvent = new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
        });

        (e.target as EventTarget).dispatchEvent(clickEvent);
    };

    if (error) {
        return <ErrorPage error={error} />;
    }

    if (!user) {
        return <Navigate replace to={`/login/${onlineCode}`} />;
    }

    if (user.onlineCode !== onlineCode) {
        //Setting timeout trick makes this operation safe
        //https://stackoverflow.com/a/69236626/22263049
        setTimeout(() => setUser(null), 0);
        return <Navigate replace to={`/login/${onlineCode}`} />;
    }

    const isZoomAvailable =
        session &&
        session.streamingLogin?.length &&
        session.streamingPassword?.length &&
        session.zoomSignature?.length;

    if (isZoomAvailable && isVideoAllowed) {
        const lang =
            LanguageMap[i18n.language as keyof typeof LanguageMap] || "en-US";
        loadZoomMeetingClient({
            lang,
            user,
            session,
            setSession,
        });

        return (
            <Draggable
                bounds={false}
                handle=".handle"
                onStart={handleDragStart}
                onStop={handleDragStop}
            >
                <ZoomVoteDesign>
                    <ParticipantNameWrapper className="handle">
                        <StyledName>
                            {user.displayName}
                            {session && liveDeviceCount > 1 && (
                                <StyledSpanDevicesCount>
                                    ({liveDeviceCount}
                                    <span>{i18n.t("connectedDevices")}</span>
                                    <DevicesIcon />)
                                </StyledSpanDevicesCount>
                            )}
                        </StyledName>
                    </ParticipantNameWrapper>
                    <div>{renderComponentVoteTemplate()}</div>
                    <StreamingToggleStyled
                        onClick={() => {
                            if (window.confirm(i18n.t("disableVideoConfirm"))) {
                                setIsAllowedVideoAllowed(!isVideoAllowed);
                            }
                        }}
                    >
                        <img
                            src={DisableStreamingLogo}
                            alt="Enabled streaming"
                        />
                        <span dir={i18n.dir(i18n.language)}>
                            {i18n.t("disableVideo")}
                        </span>
                    </StreamingToggleStyled>
                </ZoomVoteDesign>
            </Draggable>
        );
    }

    return (
        <Layout isSocketError={isSocketError}>
            <>
                <StyledName>
                    {user.displayName}
                    {session && liveDeviceCount > 1 && (
                        <StyledSpanDevicesCount>
                            <DevicesIcon />
                            {liveDeviceCount}
                            <span>{i18n.t("connectedDevices")}</span>
                        </StyledSpanDevicesCount>
                    )}
                </StyledName>

                {renderComponentVoteTemplate()}

                {isZoomAvailable && !isVideoAllowed && (
                    <StreamingToggleStyled
                        onClick={() => {
                            setIsAllowedVideoAllowed(true);
                        }}
                    >
                        <img
                            src={EnableStreamingLogo}
                            alt="Enabled streaming "
                        />
                        <span dir={i18n.dir(i18n.language)}>
                            {i18n.t("enableVideo")}
                        </span>
                    </StreamingToggleStyled>
                )}
            </>
        </Layout>
    );
};

const ZoomVoteDesign = styled.div`
    @media (min-width: 768px) {
        max-width: 320px;
    }
    position: absolute;
    width: 310px;
    background: #fff;
    border-radius: 4px;
    padding: 16px 8px;
    float: left;
    cursor: move;
    border: 1px solid #c1c1c1;
    box-shadow: 0 0 4px 2px #888585a6;
    top: 15px;
    right: 15px;
    margin: 0; /* à garder pour le draggable */
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`;

const NoVoteStyled = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1.5rem;
    font-size: 14px;
    > p {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
`;

const StyledSpanDevicesCount = styled.span`
    align-items: center;
    display: flex;
    width: 3.5rem;
    justify-content: space-between;
    margin-left: 1rem;
    width: 166px;
    margin-left: auto;
    font-weight: 100;
`;

const StyledName = styled.h1`
    font-size: 14px;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: left;
    color: #626262;
    display: flex;
    align-items: center;
`;

const StyledNoVoteParagraph = styled.h1`
    font-size: 14px;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: left;
    color: #d9001b;
    font-weight: 100;
`;

const RefreshBtn = styled(BasicButton)`
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    background-color: white;
    color: black;
    width: 9rem;
    margin-top: 0.5rem;
    :hover {
        cursor: pointer;
        filter: brightness(0.8);
    }
`;

const ConnectedMessage = styled.p`
    margin-top: 50px;
`;

//Mobile dragger hook for zoom
const ParticipantNameWrapper = styled.div`
    height: 3.5rem;
`;

const StreamingToggleStyled = styled.div`
    z-index: 5;
    cursor: pointer;
    max-width: 150px;
    margin: 24px auto 8px;
    text-align: center;
    font-size: 10px;
    color: #607d91;

    img {
        width: 75px;
        display: block;
        margin: auto;
    }
`;

export default SessionPage;
